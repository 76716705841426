$include-column-box-sizing: true !default;

@import './grid-variables';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/maps';
@import 'bootstrap/scss/mixins/lists';
@import 'bootstrap/scss/mixins/breakpoints';
@import 'bootstrap/scss/mixins/container';
@import 'bootstrap/scss/mixins/grid';
@import 'bootstrap/scss/mixins/utilities';

@import 'bootstrap/scss/vendor/rfs';

@import 'bootstrap/scss/containers';
@import 'bootstrap/scss/grid';

@import 'bootstrap/scss/utilities';

$utilities: map-get-multiple(
  $utilities,
  (
    'display',
    'order',
    'flex',
    'flex-direction',
    'flex-grow',
    'flex-shrink',
    'flex-wrap',
    'justify-content',
    'align-items',
    'align-content',
    'align-self',
    'margin',
    'margin-x',
    'margin-y',
    'margin-top',
    'margin-end',
    'margin-bottom',
    'margin-start',
    'negative-margin',
    'negative-margin-x',
    'negative-margin-y',
    'negative-margin-top',
    'negative-margin-end',
    'negative-margin-bottom',
    'negative-margin-start',
    'padding',
    'padding-x',
    'padding-y',
    'padding-top',
    'padding-end',
    'padding-bottom',
    'padding-start'
  )
);

@import 'bootstrap/scss/utilities/api';

.container {
  @include make-container();
}
