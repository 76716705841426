@import url(https://static.vgcontent.info/web/vui/add-ons/fs/u/u.css);

@import "vg-trade-design-tokens/dist/scss/index";

@import "./vendor/text-crop.scss";

@import "./abstracts/variables";
@import "./abstracts/typography";
@import "./abstracts/borders";
@import "./abstracts/forms";
@import "./abstracts/spacing";
@import "./abstracts/accessibility";

@import "./components/buttons";
@import "./components/inline-lists";
@import "./components/forms";
@import "./components/guidelet";
@import "./components/tables/holdings";
@import "./components/tables/orders";
@import "./components/tables/lot";
@import "./components/modal";
@import "./components/shapes";
@import "./components/list-group";

@import "./base/animations";
@import "./base/typography";
@import "./base/display";
@import "./base/colors";
@import "./base/spacing";
@import "./base/layout";
