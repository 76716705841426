html {
  box-sizing: border-box;
  /* Prevent font scaling in landscape while allowing user zoom */
  -webkit-text-size-adjust: 100%;
}

*, *::before, *::after {
  box-sizing: inherit;
}

blockquote,
body,
button,
dd,
div,
dl,
figcaption,
figure,
h1,
h2,
h3,
h4,
h5,
h6,
header,
html,
p,
section {
  margin: 0;
  padding: 0;
}

button,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
}

img {
  max-width: 100%;
  height: auto;
  display: block;
}

a {
  text-decoration: none;
}

input,
button,
textarea,
select {
  font-family: inherit;
}


@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

button:-moz-focusring, input:-moz-focusring, select:-moz-focusring {
  color: transparent;
  text-shadow: none;
}
button::-moz-focus-inner, input::-moz-focus-inner, select::-moz-focus-inner {
  border: 0;
}
