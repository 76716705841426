/*
|===============================================
| Basic Focus State
|===============================================
|
| A basic focus state intended to be usable for
| most DOM elements. Not intended for use with
| tds-buttons, which are handled separately.
|
*/

@mixin focus-state() {
  outline: none;
  box-shadow:
    0 0 0 2px $tds-color-focus-inside,
    0 0 0 4px $tds-color-focus-outside
}
