@use 'sass:math';

@mixin tds-text-crop($line-height: 1.3, $top-adjustment: 0px, $bottom-adjustment: 0px) {
  // Configured in Step 1
  $top-crop: 6;
  $bottom-crop: 17;
  $crop-font-size: 36;
  $crop-line-height: 1.333;

  // Apply values to calculate em-based margins that work with any font size
  $dynamic-top-crop: math.div(max($top-crop + ($line-height - $crop-line-height) * math.div($crop-font-size, 2), 0), $crop-font-size);
  $dynamic-bottom-crop: math.div(max($bottom-crop + ($line-height - $crop-line-height) * math.div($crop-font-size, 2), 0), $crop-font-size);

  // Mixin output
  line-height: $line-height;

  &::before,
  &::after {
    content: "";
    display: block;
    height: 0;
    width: 0;
  }

  &::before {
    margin-bottom: calc(-#{$dynamic-top-crop}em + #{$top-adjustment});
  }

  &::after {
    margin-top: calc(-#{$dynamic-bottom-crop}em + #{$bottom-adjustment});
  }
}
// Mixin generated at: http://text-crop.eightshapes.com/?typeface-selection=custom-font&typeface=Lato&custom-typeface-name=Univers%20LT%20Std&custom-typeface-url=https%3A%2F%2Finvestor.vanguard.com%2Fcorporate-portal%2Fpkg%2F1.0.0%2Fweb%2FUniversLTStd.4143eb05ebb8026725a1.woff2&custom-typeface-weight=400&custom-typeface-style=normal&weight-and-style=100&size=36&line-height=1.2&top-crop=8&bottom-crop=10

/* Usage Examples
  .my-level-1-heading-class {
      @include text-crop; // Will use default line height of 1.3
      font-size: 48px;
      margin: 0 0 0 16px;
  }

  .my-level-2-heading-class {
      @include text-crop; // Will use default line height of 1.3
      font-size: 32px; // Don't need to change any settings, will work with any font size automatically
      margin: 0 0 0 16px;
  }

  .my-body-copy-class {
      @include text-crop($line-height: 2); // Larger line height desired, set the line height via the mixin
      font-size: 16px;
  }

  // Sometimes depending on the font-size, the rendering, the browser, etc. you may need to tweak the output. 
  // You can adjust the top and bottom cropping when invoking the component using the $top-adjustment and $bottom-adjustment settings 
  
  .slight-adjustment-needed {
      @include text-crop($top-adjustment: -0.5px, $bottom-adjustment: 2px);
      font-size: 17px;
  }

  .dont-do-this {
      @include text-crop;
      font-size: 16px;
      line-height: 3; // DO NOT set line height outside of the mixin, the mixin needs the line height value to calculate the crop correctly
  }
*/
