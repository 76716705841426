/*
|===============================================
| Border Radii
|===============================================
*/

@mixin tds-top-border-radius() {
  border-top-right-radius: $border-radius-default;
  border-top-left-radius: $border-radius-default;
}

@mixin tds-bottom-border-radius() {
  border-bottom-right-radius: $border-radius-default;
  border-bottom-left-radius: $border-radius-default;
}