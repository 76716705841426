@use "sass:map";
@import '../variables';

$c11n-elevation-levels: 0, 100, 400, 800, 1600, 3200;
@each $level in $c11n-elevation-levels {
  .c11n-elevation-#{$level}{
    @include c11n-elevation($level)
  }

  .c11n-z-index-#{$level} {
    z-index: map.get($c11n-z-index, $level);
  }
  
  .c11n-box-shadow-#{$level} {
    box-shadow: map.get($c11n-box-shadow, $level);
  }
}