@import "@vg-constellation/styles/variables";

/*
|===============================================
| List Group Block
|===============================================
*/

.tds-list-group {
  background-color: $white;
  font-size: $s-font-size;
  line-height: $s-font-line-height;
  padding: 0;
  margin: 0;

  /*
  |===============================================
  | List Group Item Element
  |===============================================
  */

  &__item {
    @include tdt-primary-font("light");
    border-bottom: 1px $border-style-default $c11n-color-ink-300;
    color: $c11n-color-ink-400;
    display: flex;
    justify-content: space-between;
    padding: $spacing-6 0;

    &:last-child {
      border-bottom-color: $c11n-color-ink-400;
    }

    &:last-child.tds-list-group__item--revert-border-color {
      border-bottom-color: $c11n-color-ink-300;
    }
  }

  /*
  |===============================================
  | List Group Data Element
  |===============================================
  */

  &__data {
    @include tdt-primary-font("bold");
    color: $black;
  }
}
