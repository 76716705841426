
$color-gray-map: (
  'color-gray-050': (#f2f2f2),
  'color-gray-075': (#e9e9e9),
  'color-gray-010': (#f8f8f8),
  'color-gray-100': (#e5e5e5),
  'color-gray-200': (#cccccc),
  'color-gray-300': (#b3b3b3),
  'color-gray-400': (#999999),
  'color-gray-500': (#808080),
  'color-gray-600': (#666666),
  'color-gray-700': (#4d4d4d),
  'color-gray-800': (#333333),
  'color-gray-900': (#1a1a1a),
);
