/*
|===============================================
| Grays
|===============================================
*/

@mixin tdt-color-grays {
  @each $name, $hex in $color-gray-map {
    &-#{$name} {
      color: $hex;
    }
  }
}

@mixin tdt-background-color-grays {
  @each $name, $hex in $color-gray-map {
    &-#{$name} {
      background-color: $hex;
    }
  }
}

/*
|===============================================
| Brand Colors
|===============================================
*/

@mixin tdt-color-brand {
  @each $name, $hex in $color-brand-map {
    &-#{$name} {
      color: $hex;
    }
  }
}

@mixin tdt-background-color-brand {
  @each $name, $hex in $color-brand-map {
    &-#{$name} {
      background-color: $hex;
    }
  }
}
