/*
|===============================================
| Maps
|===============================================
*/

$tds-breakpoint-token-map: (
  xs: $breakpoints-xs,
  s: $breakpoints-s,
  m: $breakpoints-m,
  l: $breakpoints-l,
  xl: $breakpoints-xl,
  xxl: $breakpoints-xxl,
);

$tds-spacing-map: (
  0: $spacing-0,
  1: $spacing-1,
  2: $spacing-2,
  3: $spacing-3,
  4: $spacing-4,
  5: $spacing-5,
  6: $spacing-6,
  7: $spacing-7,
  8: $spacing-8,
  9: $spacing-9,
  10: $spacing-10,
  11: $spacing-11,
  12: $spacing-12,
  13: $spacing-13,
  14: $spacing-14,
);

/*
|===============================================
| Margin Generators
|===============================================
|
| When included, will generate a class
| for each value in the spacing map.
|
*/

@mixin tds-margin-generator {
  @each $name, $spacing in $tds-spacing-map {
    &-#{$name} {
      margin: $spacing;
    }
  }

  @each $breakpointId, $breakpointValue in $tds-breakpoint-token-map {
    @each $spacingId, $spacingValue in $tds-spacing-map {
      @media (min-width: $breakpointValue) {
        &-#{$breakpointId}-#{$spacingId} {
          margin: $spacingValue;
        }
      }
    }
  }
}

@mixin tds-margin-top-generator {
  @each $name, $spacing in $tds-spacing-map {
    &-#{$name} {
      margin-top: $spacing;
    }
  }

  @each $breakpointId, $breakpointValue in $tds-breakpoint-token-map {
    @each $spacingId, $spacingValue in $tds-spacing-map {
      @media (min-width: $breakpointValue) {
        &-#{$breakpointId}-#{$spacingId} {
          margin-top: $spacingValue;
        }
      }
    }
  }
}

@mixin tds-margin-right-generator {
  @each $name, $spacing in $tds-spacing-map {
    &-#{$name} {
      margin-right: $spacing;
    }
  }

  @each $breakpointId, $breakpointValue in $tds-breakpoint-token-map {
    @each $spacingId, $spacingValue in $tds-spacing-map {
      @media (min-width: $breakpointValue) {
        &-#{$breakpointId}-#{$spacingId} {
          margin-right: $spacingValue;
        }
      }
    }
  }
}

@mixin tds-margin-bottom-generator {
  @each $name, $spacing in $tds-spacing-map {
    &-#{$name} {
      margin-bottom: $spacing;
    }
  }

  @each $breakpointId, $breakpointValue in $tds-breakpoint-token-map {
    @each $spacingId, $spacingValue in $tds-spacing-map {
      @media (min-width: $breakpointValue) {
        &-#{$breakpointId}-#{$spacingId} {
          margin-bottom: $spacingValue;
        }
      }
    }
  }
}

@mixin tds-margin-left-generator {
  @each $name, $spacing in $tds-spacing-map {
    &-#{$name} {
      margin-left: $spacing;
    }
  }

  @each $breakpointId, $breakpointValue in $tds-breakpoint-token-map {
    @each $spacingId, $spacingValue in $tds-spacing-map {
      @media (min-width: $breakpointValue) {
        &-#{$breakpointId}-#{$spacingId} {
          margin-left: $spacingValue;
        }
      }
    }
  }
}

@mixin tds-margin-x-generator {
  @each $name, $spacing in $tds-spacing-map {
    &-#{$name} {
      margin-left: $spacing;
      margin-right: $spacing;
    }
  }

  @each $breakpointId, $breakpointValue in $tds-breakpoint-token-map {
    @each $spacingId, $spacingValue in $tds-spacing-map {
      @media (min-width: $breakpointValue) {
        &-#{$breakpointId}-#{$spacingId} {
          margin-left: $spacingValue;
          margin-right: $spacingValue;
        }
      }
    }
  }
}

@mixin tds-margin-y-generator {
  @each $name, $spacing in $tds-spacing-map {
    &-#{$name} {
      margin-top: $spacing;
      margin-bottom: $spacing;
    }
  }

  @each $breakpointId, $breakpointValue in $tds-breakpoint-token-map {
    @each $spacingId, $spacingValue in $tds-spacing-map {
      @media (min-width: $breakpointValue) {
        &-#{$breakpointId}-#{$spacingId} {
          margin-top: $spacingValue;
          margin-bottom: $spacingValue;
        }
      }
    }
  }
}

/*
|===============================================
| Padding Generators
|===============================================
|
| When included, will generate a class
| for each value in the spacing map.
|
*/

@mixin tds-padding-generator {
  @each $name, $spacing in $tds-spacing-map {
    &-#{$name} {
      padding: $spacing;
    }
  }
}

@mixin tds-padding-top-generator {
  @each $name, $spacing in $tds-spacing-map {
    &-#{$name} {
      padding-top: $spacing;
    }
  }
}

@mixin tds-padding-right-generator {
  @each $name, $spacing in $tds-spacing-map {
    &-#{$name} {
      padding-right: $spacing;
    }
  }
}

@mixin tds-padding-bottom-generator {
  @each $name, $spacing in $tds-spacing-map {
    &-#{$name} {
      padding-bottom: $spacing;
    }
  }
}

@mixin tds-padding-left-generator {
  @each $name, $spacing in $tds-spacing-map {
    &-#{$name} {
      padding-left: $spacing;
    }
  }
}

@mixin tds-padding-x-generator {
  @each $name, $spacing in $tds-spacing-map {
    &-#{$name} {
      padding-left: $spacing;
      padding-right: $spacing;
    }
  }
}

@mixin tds-padding-y-generator {
  @each $name, $spacing in $tds-spacing-map {
    &-#{$name} {
      padding-top: $spacing;
      padding-bottom: $spacing;
    }
  }
}
