@import '@vg-constellation/angular-17/styles/breakpoints';
@import '@vg-constellation/angular-17/styles/spacing';

/*
|=====================================================================
| Flexbox Layout : BEM Classes
|=====================================================================
|
| Combine the twe-flex class and the available modifiers as desired
| to build different flexbox containers.
|
*/

.twe-flex {
  display: flex;

  &--center-items {
    display: flex;
    align-items: center;
  }

  &--center-content {
    display: flex;
    justify-content: center;
  }
}

/*
|=====================================================================
| Flexbox Layout : Aliases
|=====================================================================
|
| Use the following aliases for common flexbox containers if you do
| not want to have to add many classes to your HTML. Do not mix and
| match the BEM and alias classes. Choose one approach or the other.
|
*/

// Alias for: class="twe-flex twe-flex--center-items twe-flex--center-content"

.twe-flex-center {
  @extend .twe-flex;
  @extend .twe-flex--center-items;
  @extend .twe-flex--center-content;
}

// Alias for: class="twe-flex twe-flex--center-items"

.twe-flex-center-items {
  @extend .twe-flex;
  @extend .twe-flex--center-items;
}

// Alias for: class="twe-flex twe-flex--center-content"

.twe-flex-center-content {
  @extend .twe-flex;
  @extend .twe-flex--center-content;
}

/*
|=====================================================================
| Misc
|=====================================================================
*/

.root-wrapper {
  display: grid;
  min-height: 100vh;
  grid-template-rows: repeat(3, auto) 1fr;

  @include media-breakpoint-down(lg) {
    display: block;
  }
}

.twe-main {
  margin-top: $c11n-size-space-2x;
  flex-grow: 1;

  @include media-breakpoint-up(lg) {
    &:not(.mt-0) {
      margin-top: $c11n-size-space-3x;
    }

    &.mt-0 {
      margin-top: 0;
    }
  }
}

.twe-content-wrapper {
  display: flex;
  flex-direction: column;

  &.beacon {
    min-height: 100vh;
  }
}

.twe-column-left {
  @include media-breakpoint-up(lg) {
    padding-right: 1.75rem;
  }
}

.twe-column-right {
  @include media-breakpoint-up(lg) {
    padding-left: 1.75rem;
  }
}

/*
|=====================================================================
| Error Page
|=====================================================================
*/

.twe-error-page {
  @extend .twe-flex-center;
  min-height: 550px;
}
