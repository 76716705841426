
$animation-timing-collapse-small: 100ms;
$animation-timing-expand-large: 300ms;
$animation-timing-expand-medium: 250ms;
$animation-timing-expand-small: 100ms;
$animation-easing-decelerate-ios: "[[CAMediaTimingFunction alloc] initWithControlPoints:0.0f:0.0f:0.2f:1.0f]";
$animation-timing-collapse-large: 250ms;
$animation-easing-accelerate-ios: "[CAMediaTimingFunction alloc] initWithControlPoints:0.4f:0.0f:1.0f:1.0f]";
$animation-easing-standard-ios: "[[CAMediaTimingFunction alloc] initWithControlPoints:0.4f:0.0f:0.2f:1.0f]";
$animation-easing-decelerate-android: LinearOutSlowInInterpolator;
$animation-easing-standard-android: FastOutSlowInInterpolator;
$animation-easing-accelerate-android: FastOutLinearInInterpolator;
$animation-timing-collapse-medium: 200ms;
$animation-easing-decelerate-web: cubic-bezier(0.0, 0.0, 0.2, 1);
$animation-easing-standard-web: cubic-bezier(0.4, 0.0, 0.2, 1);
$animation-easing-accelerate-web: cubic-bezier(0.4, 0.0, 1, 1);
$color-gray-050: #f2f2f2;
$color-gray-075: #e9e9e9;
$color-gray-010: #f8f8f8;
$color-gray-100: #e5e5e5;
$color-gray-200: #cccccc;
$color-gray-300: #b3b3b3;
$color-gray-400: #999999;
$color-gray-500: #808080;
$color-gray-600: #666666;
$color-gray-700: #4d4d4d;
$color-gray-800: #333333;
$color-gray-900: #1a1a1a;
$border-color-on-light-default: #cccccc;
$border-color-on-light-last: #666666;
$border-color-on-dark-default: #4d4d4d;
$border-color-on-dark-last: #808080;
$border-radius-default: 4px;
$border-width-default: 1px;
$border-style-default: solid;
$color-black: #000000;
$color-white: #ffffff;
$color-brand-primary: #96151d;
$color-brand-black: #1a1a1a;
$color-brand-white: #ffffff;
$color-aqua-050: #c0e5f0;
$color-aqua-100: #93d2e1;
$color-aqua-200: #55bdcf;
$color-aqua-300: #24abbb;
$color-aqua-025: #F2FBFF;
$color-aqua-400: #009aa6;
$color-aqua-500: #0096a8;
$color-aqua-600: #0090a7;
$color-aqua-700: #0086a3;
$color-aqua-800: #007b9c;
$color-aqua-900: #006e92;
$color-secondary-green: #69923a;
$color-secondary-bright-red: #f51914;
$color-secondary-positive-change: #298883;
$color-secondary-pale-gray: #f2f8fc;
$color-secondary-royal-blue: #005293;
$color-secondary-teal-2: #298883;
$color-secondary-negative-change: #e21c2d;
$color-secondary-bright-blue: #0098db;
$color-secondary-navy-blue: #003c69;
$color-secondary-orange: #e37222;
$color-secondary-slate-blue: #44697d;
$color-secondary-brown: #5a311c;
$color-secondary-orange-red: #f7440e;
$color-secondary-red-5: #e21c2d;
$color-secondary-yellow: #eaba1f;
$color-secondary-lime: #a8b400;
$color-secondary-olive: #9a8419;
$color-secondary-green-alt: #288034;
$color-secondary-dark-red: #622639;
$color-secondary-interactive: #0074c9;
$color-secondary-teal: #006778;
$color-secondary-aqua: #009aa6;
$color-secondary-purple: #80379b;
$color-utility-text-link: #0074c9;
$color-utility-secondary-text-link: #666666;
$color-utility-confirmation: #008000;
$color-utility-information: #005392;
$color-utility-internal: #bbcf9c;
$color-utility-error: #e00000;
$color-utility-warning: #efab1f;
$color-utility-warning-new: #e37222;
$elevation-height-umbra-0: 0px 0px 0px 0px;
$elevation-height-umbra-1: 0px 2px 1px -1px;
$elevation-height-umbra-20: 0px 10px 13px -6px;
$elevation-height-umbra-2: 0px 3px 1px -2px;
$elevation-height-umbra-10: 0px 6px 6px -3px;
$elevation-height-umbra-21: 0px 10px 13px -6px;
$elevation-height-umbra-3: 0px 3px 3px -2px;
$elevation-height-umbra-11: 0px 6px 7px -4px;
$elevation-height-umbra-22: 0px 10px 14px -6px;
$elevation-height-umbra-4: 0px 2px 4px -1px;
$elevation-height-umbra-12: 0px 7px 8px -4px;
$elevation-height-umbra-23: 0px 11px 14px -7px;
$elevation-height-umbra-5: 0px 3px 5px -1px;
$elevation-height-umbra-13: 0px 7px 8px -4px;
$elevation-height-umbra-24: 0px 11px 15px -7px;
$elevation-height-umbra-6: 0px 3px 5px -1px;
$elevation-height-umbra-14: 0px 7px 9px -4px;
$elevation-height-umbra-7: 0px 4px 5px -2px;
$elevation-height-umbra-15: 0px 8px 9px -5px;
$elevation-height-umbra-8: 0px 5px 5px -3px;
$elevation-height-umbra-16: 0px 8px 10px -5px;
$elevation-height-umbra-9: 0px 5px 6px -3px;
$elevation-height-umbra-17: 0px 8px 11px -5px;
$elevation-height-umbra-18: 0px 9px 11px -5px;
$elevation-height-umbra-19: 0px 9px 12px -6px;
$elevation-height-penumbra-7: 0px 7px 10px 1px;
$elevation-height-penumbra-8: 0px 8px 10px 1px;
$elevation-height-penumbra-9: 0px 9px 12px 1px;
$elevation-height-penumbra-20: 0px 20px 31px 3px;
$elevation-height-penumbra-10: 0px 10px 14px 1px;
$elevation-height-penumbra-21: 0px 21px 33px 3px;
$elevation-height-penumbra-11: 0px 11px 15px 1px;
$elevation-height-penumbra-22: 0px 22px 35px 3px;
$elevation-height-penumbra-12: 0px 12px 17px 2px;
$elevation-height-penumbra-23: 0px 23px 36px 3px;
$elevation-height-penumbra-0: 0px 0px 0px 0px;
$elevation-height-penumbra-13: 0px 13px 19px 2px;
$elevation-height-penumbra-24: 0px 24px 38px 3px;
$elevation-height-penumbra-1: 0px 1px 1px 0px;
$elevation-height-penumbra-14: 0px 14px 21px 2px;
$elevation-height-penumbra-2: 0px 2px 2px 0px;
$elevation-height-penumbra-15: 0px 15px 22px 2px;
$elevation-height-penumbra-3: 0px 3px 4px 0px;
$elevation-height-penumbra-16: 0px 16px 24px 2px;
$elevation-height-penumbra-4: 0px 4px 5px 0px;
$elevation-height-penumbra-17: 0px 17px 26px 2px;
$elevation-height-penumbra-5: 0px 5px 8px 0px;
$elevation-height-penumbra-18: 0px 18px 28px 2px;
$elevation-height-penumbra-6: 0px 6px 10px 0px;
$elevation-height-penumbra-19: 0px 19px 29px 2px;
$elevation-height-ambient-0: 0px 0px 0px 0px;
$elevation-height-ambient-1: 0px 1px 3px 0px;
$elevation-height-ambient-2: 0px 1px 5px 0px;
$elevation-height-ambient-3: 0px 1px 8px 0px;
$elevation-height-ambient-4: 0px 1px 10px 0px;
$elevation-height-ambient-20: 0px 8px 38px 7px;
$elevation-height-ambient-10: 0px 4px 18px 3px;
$elevation-height-ambient-5: 0px 1px 14px 0px;
$elevation-height-ambient-21: 0px 8px 40px 7px;
$elevation-height-ambient-11: 0px 4px 20px 3px;
$elevation-height-ambient-6: 0px 1px 18px 0px;
$elevation-height-ambient-22: 0px 8px 42px 7px;
$elevation-height-ambient-12: 0px 5px 22px 4px;
$elevation-height-ambient-7: 0px 2px 16px 1px;
$elevation-height-ambient-23: 0px 9px 44px 8px;
$elevation-height-ambient-13: 0px 5px 24px 4px;
$elevation-height-ambient-8: 0px 3px 14px 2px;
$elevation-height-ambient-24: 0px 9px 46px 8px;
$elevation-height-ambient-14: 0px 5px 26px 4px;
$elevation-height-ambient-9: 0px 3px 16px 2px;
$elevation-height-ambient-15: 0px 6px 28px 5px;
$elevation-height-ambient-16: 0px 6px 30px 5px;
$elevation-height-ambient-17: 0px 6px 32px 5px;
$elevation-height-ambient-18: 0px 7px 34px 6px;
$elevation-height-ambient-19: 0px 7px 36px 6px;
$elevation-color-umbra: rgba(0,0,0,0.2);
$elevation-color-penumbra: rgba(0,0,0,0.14);
$elevation-color-ambient: rgba(0,0,0,0.12);
$screens-xl: 1440px;
$breakpoints-l: 1024px;
$breakpoints-xxl: 1560px;
$breakpoints-m: 840px;
$breakpoints-xl: 1440px;
$screens-xs: 360px;
$screens-xxl: 1920px;
$screens-l: 1040px;
$screens-m: 880px;
$breakpoints-s: 600px;
$breakpoints-xs: 0px;
$screens-s: 600px;
$spacing-stretch-2: 4px 8px;
$spacing-stretch-3: 6px 12px;
$spacing-stretch-4: 8px 16px;
$spacing-stack-1: 0 0 2px;
$spacing-stretch-5: 12px 24px;
$spacing-stack-2: 0 0 4px;
$spacing-stretch-6: 16px 32px;
$spacing-stack-3: 0 0 6px;
$spacing-stack-4: 0 0 8px;
$spacing-0: 0;
$spacing-stack-5: 0 0 12px;
$spacing-1: 2px;
$spacing-stack-6: 0 0 16px;
$spacing-2: 4px;
$spacing-stack-7: 0 0 24px;
$spacing-3: 6px;
$spacing-stack-8: 0 0 32px;
$spacing-10: 48px;
$spacing-4: 8px;
$spacing-stack-9: 0 0 40px;
$spacing-11: 56px;
$spacing-5: 12px;
$spacing-12: 64px;
$spacing-6: 16px;
$spacing-13: 72px;
$spacing-7: 24px;
$spacing-squash-1: 0 4px;
$spacing-14: 80px;
$spacing-8: 32px;
$spacing-squash-2: 0 8px;
$spacing-stack-10: 0 0 48px;
$spacing-9: 40px;
$spacing-squash-3: 0 12px;
$spacing-stack-11: 0 0 56px;
$spacing-squash-4: 0 16px;
$spacing-stack-12: 0 0 64px;
$spacing-squash-5: 0 24px;
$spacing-stack-13: 0 0 72px;
$spacing-squash-6: 0 32px;
$spacing-stack-14: 0 0 80px;
$spacing-inline-left-1: 0 0 0 2px;
$spacing-inline-right-1: 0 0 2px;
$spacing-inline-left-2: 0 0 0 4px;
$spacing-inline-right-2: 0 0 4px;
$spacing-inline-left-3: 0 0 0 6px;
$spacing-inset-1: 2px;
$spacing-inline-right-3: 0 0 6px;
$spacing-inline-left-4: 0 0 0 8px;
$spacing-inset-2: 4px;
$spacing-inline-right-4: 0 0 8px;
$spacing-inline-left-5: 0 0 0 12px;
$spacing-inset-10: 48px;
$spacing-inset-3: 6px;
$spacing-inline-right-5: 0 0 12px;
$spacing-inline-left-6: 0 0 0 16px;
$spacing-inline-right-10: 0 0 48px;
$spacing-inset-11: 56px;
$spacing-inset-4: 8px;
$spacing-inline-right-6: 0 0 16px;
$spacing-inline-left-10: 0 0 0 48px;
$spacing-inline-left-7: 0 0 0 24px;
$spacing-inline-right-11: 0 0 56px;
$spacing-inset-12: 64px;
$spacing-inset-5: 12px;
$spacing-inline-right-7: 0 0 24px;
$spacing-inline-left-11: 0 0 0 56px;
$spacing-inline-left-8: 0 0 0 32px;
$spacing-inline-right-12: 0 0 64px;
$spacing-inset-13: 72px;
$spacing-inset-6: 16px;
$spacing-inline-right-8: 0 0 32px;
$spacing-inline-left-12: 0 0 0 64px;
$spacing-inline-left-9: 0 0 0 40px;
$spacing-inline-right-13: 0 0 72px;
$spacing-inset-14: 80px;
$spacing-inset-7: 24px;
$spacing-inline-right-9: 0 0 40px;
$spacing-inline-left-13: 0 0 0 72px;
$spacing-inline-right-14: 0 0 80px;
$spacing-inset-8: 32px;
$spacing-inline-left-14: 0 0 0 80px;
$spacing-stretch-1: 2px 4px;
$spacing-inset-9: 40px;
$surface-default: #ffffff;
$surface-dark: #1a1a1a;
$surface-gray: #f2f2f2;
$surface-emphasis-on-light: #e5e5e5;
$surface-emphasis-on-dark: #333333;
$font-family-medium: 'Univers-Basic-Medium', Arial, sans-serif;
$m-font-line-height: 24px;
$xxl-font-line-height: 52px;
$l-font-line-height: 32px;
$s-font-size: 14px;
$font-weight-regular: 400;
$xxs-font-size: 10px;
$font-family-condensed-light: 'Univers-Condensed-Light', Arial, sans-serif;
$xxxl-font-size: 68px;
$font-family-condensed-medium: 'Univers-Condensed-Medium', Arial, sans-serif;
$xl-font-size: 28px;
$font-family-light: 'Univers-Basic-Light', Arial, sans-serif;
$font-family-condensed-regular: 'Univers-Condensed-Regular', Arial, sans-serif;
$font-family-regular-italic: 'Univers-Basic-Regular-Italic', Arial, sans-serif;
$font-weight-medium: 500;
$xs-font-size: 12px;
$font-color-on-light: #1a1a1a;
$font-family-bold: 'Univers-Basic-Bold', Arial, sans-serif;
$font-family-regular: 'Univers-Basic-Regular', Arial, sans-serif;
$font-color-low-emphasis-on-dark: #cccccc;
$xs-font-line-height: 16px;
$font-color-low-emphasis-on-light: #666666;
$xl-font-line-height: 36px;
$xxxl-font-line-height: 76px;
$font-weight-bold: 700;
$font-color-on-dark: #ffffff;
$xxs-font-line-height: 10px;
$s-font-line-height: 20px;
$l-font-size: 24px;
$xxl-font-size: 44px;
$m-font-size: 16px;
$font-weight-light: 200;
$z-index-0: 0;
$z-index-100: 100;
$z-index-200: 200;
$z-index-300: 300;
$z-index-400: 400;
$z-index-top: 99999;
$z-index-500: 500;
$z-index-bottom: -100;
$z-index-auto: auto;
