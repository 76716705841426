@import "@vg-constellation/styles/variables";

/*
|===============================================
| Misc Colors
|===============================================
*/

$tds-color-brand-primary-hover: #ba2028;
$tds-color-secondary-pale-gray-hover: #e2e8ec;
$tds-color-secondary-interactive-dark: #0068B9;

/*
|===============================================
| Focus State Colors
|===============================================
*/

$tds-color-focus-inside: $white;
$tds-color-focus-outside: #0f62c5; //copied from c11n focus color, not available as token in c11n

/*
|===============================================
| Borders
|===============================================
*/

$default-border: $border-width-default $border-style-default $c11n-color-ink-300;