@import '../variables';

ul.c11n-list {
  list-style: disc;
  padding-left: 0;
  margin: 0;

  > li {
    position: relative;
    padding-left: rem($c11n-size-space-2x);
    margin-bottom: rem($c11n-size-space-1point5x);
    color: $c11n-color-ink-800;

    &::marker {
      color: transparent;
    }

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: calc(11 / #{strip-unit($c11n-size-typography-md)} * 1em);
      left: rem(2);
      border-radius: 50%;
      background: currentColor;
      width: rem($c11n-size-space-0point5x);
      height: rem($c11n-size-space-0point5x);
    }
    &.c11n-list-icon--black {
      svg {
        use {
          stroke: $c11n-color-ink-800;
        }
      }
    }
    &.c11n-list-icon--white {
      color: $c11n-color-ink-000;
      svg {
        use {
          stroke: $c11n-color-ink-000;
        }
      }
    }
    &.c11n-list-icon--subtle {
      svg {
        use {
          stroke: $c11n-color-ink-400;
        }
      }
    }
    &.c11n-list-icon--brand {
      svg {
        use {
          stroke: $c11n-color-red-600;
        }
      }
    }
    &.c11n-list-icon--alert {
      svg {
        use {
          stroke: $c11n-color-alert-400;
        }
      }
    }
    &.c11n-list-icon--success {
      svg {
        use {
          stroke: $c11n-color-success-400;
        }
      }
    }
  }

  ul li::before {
    color: currentColor;
    border-radius: 0;
    height: rem(2);
    top: calc(12 / #{strip-unit($c11n-size-typography-md)} * 1em);
  }

  ul, ol {
    margin-top: rem($c11n-size-space-1point5x);
  }
}

ol.c11n-list {
  padding-left: 0;
  counter-reset: section;
  list-style: none;
  margin: 0;

  > li {
    position: relative;
    margin-bottom: rem($c11n-size-space-1point5x);
    padding-left: calc(26 / #{strip-unit($c11n-size-typography-md)} * 1em + #{rem($c11n-size-space-1x)});
    color: $c11n-color-ink-800;
    &::before {
      counter-increment: section;
      content: counter(section) ".";
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      width: calc(26 / #{strip-unit($c11n-size-typography-md)} * 1em);
      text-align: right;
      font-variant-numeric: tabular-nums;
      color: currentColor;
    }
  }

  ul, ol {
    margin-top: rem($c11n-size-space-1point5x);
  }
}
ul.c11n-list--icons {
  > li {
    position: relative;
    padding-left: rem($c11n-size-space-5x);
    margin-top: rem($c11n-size-space-0point5x);
    margin-bottom: rem($c11n-size-space-2point5x);

    svg[data-svg="marker-icon"] {
      display: block;
      position: absolute;
      left: 0;
      top: rem(2);
      width: rem($c11n-size-space-3x);
      height: rem($c11n-size-space-3x);
    }

    &::before {
      content: '';
      display: none;
    }
  }
}
ol.c11n-list--circles {
  padding-left: 0;
  counter-reset: section;
  list-style: none;
  margin: 0;

  > li {
    position: relative;
    margin-top: rem($c11n-size-space-0point5x);
    margin-bottom: rem($c11n-size-space-2point5x);
    padding: 0;
    padding-left: rem($c11n-size-space-6x);

    &::before {
      content: counter(section) "";
      top: rem(-2);
      left: 0;
      width: rem($c11n-size-space-4x);
      max-width: rem($c11n-size-space-4x);
      height: rem($c11n-size-space-4x);
      font-size: rem(17);
      font-weight: bold;
      line-height: rem(30);
      border-radius: 50%;
      background: $c11n-color-turquoise-100;
      text-align: center;
      font-variant-numeric: tabular-nums;
      color: currentColor;
    }
  }
}
.c11n-text-xs {
  ol.c11n-list--circles {
    li::before {
      top: rem(-6);
    }
  }
  ul.c11n-list--icons {
    svg[data-svg="marker-icon"] {
      top: rem(-6);
    }
  }
}
.c11n-text-sm {
  ol.c11n-list--circles {
    li::before {
      top: rem(-4);
    }
  }
  ul.c11n-list--icons {
    svg[data-svg="marker-icon"] {
      top: rem(-2);
    }
  }
}
.c11n-text-lg {
  ol.c11n-list--circles {
    li::before {
      top: rem(2);
    }
  }
  ul.c11n-list--icons {
    svg[data-svg="marker-icon"] {
      top: rem(4);
    }
  }
}

ol.c11n-list--circles.c11n-list--yellow {
  > li::before {
    background: $c11n-color-yellow-100;
  }
}
ol.c11n-list--circles.c11n-list--red {
  > li::before {
    background: $c11n-color-red-100;
  }
}
dl.c11n-list {
  color: $c11n-color-ink-800;
  &--bold {
    > div > dt, > div > div dt:first-child {
      font-weight: $c11n-font-weight-bold;
    }
  }
  &--subtle {
    dt {
      color: $c11n-color-ink-400;
    }
  }

  > div {
    display: flex;
    flex-flow: row wrap;
    gap: rem($c11n-size-space-3x);
    padding: rem($c11n-size-space-2point5x) 0;
    border-bottom: 1px solid $c11n-color-ink-300;

    > dt, > div, dd {
      flex-basis: calc(50% - #{rem($c11n-size-space-1point5x)});
      word-break: break-word;
    }

    dd {
      text-align: right;
    }
  }

  &--stacked {
    > div {
      dt, dd {
        flex-basis: 100%;
        text-align: left;
      }
    }
  }
}
