@import "../abstracts/variables";
@import "@vg-constellation/styles/variables";


/*
|===============================================
| Form Control Input
|===============================================
|
| Base styles to make a class
| into a form control input.
|
*/

@mixin tds-form-control-input() {
  @extend %form-control__input;

  &:not(:disabled):hover,
  &:not(:disabled):focus,
  &--focus {
    @extend %form-control__input--hover-focus;
  }

  &:not(:disabled):hover {
    @extend %form-control__input--hover;
  }

  &:not(:disabled):focus {
    @extend %form-control__input--focus;
  }

  &:focus,
  &--focus {
    @extend %form-control__input--focus;
  }

  &:disabled,
  &--disabled {
    @include tds-disabled-input();
  }

  &--error {
    @include tds-error-input();
  }
}

/*
|===============================================
| Disabled Form Control Input State
|===============================================
*/

@mixin tds-disabled-input() {
  border-color: $c11n-color-ink-300;
  color: $c11n-color-ink-300;
  pointer-events: none;

  &::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: $c11n-color-ink-300;
  }

  &::-moz-placeholder {
    /* Firefox 19+ */
    color: $c11n-color-ink-300;
  }

  &:-ms-input-placeholder {
    /* IE 10+ */
    color: $c11n-color-ink-300;
  }

  &:-moz-placeholder {
    /* Firefox 18- */
    color: $c11n-color-ink-300;
  }

  &::placeholder {
    color: $c11n-color-ink-300;
  }
}

/*
|===============================================
| Error Form Control Input State
|===============================================
*/

@mixin tds-error-input() {
  border: 2px $border-style-default $error-color;
  padding: 0 $spacing-6 - 1px;

  &:not(:disabled):hover,
  &:not(:disabled):focus {
    border: 2px $border-style-default $error-color;
  }
}

/*
|===============================================
| Form Field
|===============================================
|
| A container for form field elements.
|
*/

%form-field {
  @include tdt-primary-font("regular");
}

/*
|===============================================
| Form Field Label
|===============================================
|
| A label that sits outside of the form control.
|
*/

%form-field__label {
  color: $c11n-color-ink-600;
  display: inline-block;
  font-size: $s-font-size;
  line-height: $s-font-line-height;
  margin-bottom: $spacing-1;
  padding-left: $spacing-6;
}

/*
|===============================================
| Form Field Message
|===============================================
*/

%form-field__message {
  font-size: $xs-font-size;
  margin-top: 3px;
  padding-left: $spacing-6;
}

/*
|===============================================
| Form Control
|===============================================
|
| A relative container for form controls.
|
*/

%form-control {
  @include tdt-primary-font("regular");
  position: relative;
}

/*
|===============================================
| Form Control Inputs
|===============================================
*/

// Shared styles
// ---

%form-control__input {
  @include tdt-primary-font("regular");
  background-color: $white;
  border: $border-width-default $border-style-default $c11n-color-ink-400;
  border-radius: $border-radius-default;
  box-sizing: border-box;
  color: $black;
  display: block;
  font-size: $m-font-size;
  height: 56px;
  padding: 0 $spacing-6;
  width: 100%;

  &::-ms-clear {
    display: none;
  }
}

%form-control__input--hover-focus {
  border: 2px $border-style-default transparent;
  padding: 0 $spacing-6 - 1px;
}

%form-control__input--hover {
  border-color: $black;
}

%form-control__input--focus {
  outline: none;
  box-shadow:
    0 0 0 1px $tds-color-focus-inside,
    0 0 0 3px $tds-color-focus-outside;
  border-color: $c11n-color-ink-400;
}

/*
|===============================================
| Form Control Input Size Options
|===============================================
*/

%tds-form-control-input--compact {
  height: 48px;
}

%tds-form-control-input--dense {
  height: 40px;
}