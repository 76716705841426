@import "@vg-constellation/styles/variables";

/*
|=====================================================================
| Base Styles
|=====================================================================
|
| These were originally reused between multiple table themes. That is
| no longer the case, so we should really just move them into the
| holdings theme class and update class names accordingly.
|
*/

.tds-table {
  font-size: $s-font-size;
  line-height: $s-font-line-height;
  color: $black;
}

.tds-table__row {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  padding: 16px 0;
}

.tds-table__data {
  flex: 0 1 auto;
  box-sizing: border-box;
  padding: 0 8px;
}

/*
|=====================================================================
| Holdings Table Theme
|=====================================================================
*/

.tds-table-theme-holdings {
  line-height: $s-font-line-height;
  color: $black;
  border-spacing: 2px;

  .tds-table__row {
    position: relative;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: center;
    padding-top: $spacing-6;
    border-bottom: 1px $border-style-default $c11n-color-ink-300;

    &:last-child {
      border-bottom: none;
    }
  }

  .tds-table__data {
    justify-content: flex-start;
    flex: 0 1 auto;
    padding: 0;
    box-sizing: border-box;
    right: auto;
    min-width: 102px;

    @media (max-width: $breakpoints_s) {
      width: 100%;
    }

    &:first-child {
      @include tdt-primary-font("bold");
      font-size: $l-font-size;
      color: $black;

      @media (max-width: $breakpoints_s) {
        padding-bottom: 10px;
        padding-top: 10px;
      }
    }

    &:nth-child(2) {
      @include tdt-primary-font("bold");
      font-size: $xs-font-size;
      color: $black;
      flex: 1;
      @media (max-width: $breakpoints_s) {
        padding-bottom: 10px;
      }
    }

    &:last-child {
      margin-left: $spacing-6;
      min-width: 144px;
      @media (max-width: $breakpoints_s) {
        margin-left: 0;
      }
    }
  }
}

tds-card-body .tds-table-theme-holdings .tds-table__row {
  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    padding-bottom: 0;
  }
}
