@import "@vg-constellation/styles/variables";

.tds-order-table {
  $parentBlock: &;
  line-height: $s-font-line-height;
  color: $black;
  border-spacing: 2px;

  &__row {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: center;
    align-items: baseline;
  }

  &__cell {
    justify-content: flex-start;
    flex: 0 1 auto;
    box-sizing: border-box;
    min-width: 116px;
  }

  &__end {
    display: flex;
    margin-top: 16px;
    flex-wrap: wrap;

    @media (max-width: $breakpoints_s) {
      #{$parentBlock}__cell {
        width: auto;
        min-width: unset;
        width: calc(33% - 5.4px);
        min-height: 62px;
        padding: 8px 0;
        border-bottom: 1px solid $c11n-color-ink-300;
        text-align: left;

        &:last-child {
          width: calc(100% - 16px);
          min-height: unset;
          border-bottom: none;
          padding-bottom: 0;
        }
      }
    }
  }

  &__data {
    @media (max-width: $breakpoints_s) {
      width: 100%;
    }

    &:first-child {
      @include tdt-primary-font("bold");
      font-size: $l-font-size;
      color: $black;

      @media (max-width: $breakpoints_s) {
        padding-bottom: 16px;
        padding-top: 10px;
      }
    }
  }
}
