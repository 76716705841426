.twe-card {
  background-color: $c11n-color-ink-000;
  border-radius: 4px;
  border: 1px solid $c11n-color-ink-300;
  padding: $c11n-size-space-2x;
  @extend .c11n-elevation-100;

  @include media-breakpoint-up(xxl) {
    padding: $c11n-size-space-3x;
  }

  &:has(.twe-card-cap) {
    padding: 0;
    border: none;

    .twe-card-body {
      padding: $c11n-size-space-2x;

      @include media-breakpoint-up(xxl) {
        padding: 0 $c11n-size-space-3x;
      }
    }
  }

  &--light {
    background-color: $c11n-color-ink-100;
  }

  &--dark {
    background-color: $c11n-color-ink-800;
    color: $c11n-color-ink-000;
  }

  h2,
  .twe-card-header {
    @include c11n-font-rules('bold', 'lg');
    @include c11n-text-crop-lg-bold();
    margin-top: 0;
    margin-bottom: $c11n-size-space-2x;
  }
}
