@import "../vendor/text-crop.scss";

/*
|===============================================
| Font Size Mixins
|===============================================
*/

@mixin tds-text-xxs() {
  font-size: $xxs-font-size;
  line-height: $xxs-font-line-height;
}

@mixin tds-text-xs() {
  font-size: $xs-font-size;
  line-height: $xs-font-line-height;
}

@mixin tds-text-s() {
  font-size: $s-font-size;
  line-height: $s-font-line-height;
}

@mixin tds-text-m() {
  font-size: $m-font-size;
  line-height: $m-font-line-height;
}

@mixin tds-text-l() {
  font-size: $l-font-size;
  line-height: $l-font-line-height;
}

@mixin tds-text-xl() {
  font-size: $xl-font-size;
  line-height: $xl-font-line-height;
}

@mixin tds-text-xxl() {
  font-size: $xxl-font-size;
  line-height: $xxl-font-line-height;
}

@mixin tds-text-xxxl() {
  font-size: $xxxl-font-size;
  line-height: $xxxl-font-line-height;
}
