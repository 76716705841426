@import '../variables';

body {
  --c11n-scrollbar-width: 0;

  margin-right: var(--c11n-scrollbar-width);
  font-family: $c11n-font-family-sans;
  font-size: rem($c11n-size-typography-md);
  line-height: 1.5;
  color: $c11n-color-ink-800;

  &.c11n-modal-dialog-open{
    overflow: hidden;
  }
}