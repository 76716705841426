/*
|=====================================================================
| Flexbox Layout : BEM Classes
|=====================================================================
|
| Combine the tds-flex class and the available modifiers as desired
| to build different flexbox containers.
|
*/

.tds-flex {
  display: flex;

  &--center-items {
    align-items: center;
  }

  &--center-content {
    justify-content: center;
  }

  &--end-content {
    justify-content: flex-end;
  }
}

/*
|=====================================================================
| Flexbox Layout : Aliases
|=====================================================================
|
| Use the following aliases for common flexbox containers if you do
| not want to have to add many classes to your HTML. Do not mix and
| match the BEM and alias classes. Choose one approach or the other.
|
*/

// Alias for: class="tds-flex tds-flex--center-items tds-flex--center-content"

.tds-flex-center {
  @extend .tds-flex;
  @extend .tds-flex--center-items;
  @extend .tds-flex--center-content;
}

// Alias for: class="tds-flex tds-flex--center-items"

.tds-flex-center-items {
  @extend .tds-flex;
  @extend .tds-flex--center-items;
}

// Alias for: class="tds-flex tds-flex--center-content"

.tds-flex-center-content {
  @extend .tds-flex;
  @extend .tds-flex--center-content;
}

// Alias for: class="tds-flex tds-flex--end-content"

.tds-flex-end-content {
  @extend .tds-flex;
  @extend .tds-flex--end-content;
}
