@import "@vg-constellation/styles/variables";

/*
|===============================================
| Colors
|===============================================
*/

.tds-text-color-on-light {
  color: $black;
}

.tds-text-color-on-dark {
  color: $white;
}

.tds-text-color-low-emphasis-on-light {
  color: $c11n-color-ink-400;
}

.tds-text-color-low-emphasis-on-dark {
  color: $c11n-color-ink-300;
}

/*
|===============================================
| Text Transforms
|===============================================
*/

.tds-text-uppercase {
  text-transform: uppercase;
}

.tds-text-capital {
  text-transform: capitalize;
}

.tds-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/*
|===============================================
| Font Weight
|===============================================
*/

.tds-text-light,
.tds-text-weight-light {
  @include tdt-primary-font("light");
}

.tds-text-regular,
.tds-text-weight-regular {
  @include tdt-primary-font("regular");
}

.tds-text-regular-italic,
.tds-text-weight-regular-italic {
  @include tdt-primary-font("regular-italic");
}

.tds-text-medium,
.tds-text-weight-medium {
  @include tdt-primary-font("medium");
}

.tds-text-bold,
.tds-text-weight-bold {
  @include tdt-primary-font("bold");
}

.tds-text-condensed-light,
.tds-text-weight-condensed-light {
  @include tdt-primary-font("condensed-light");
}

.tds-text-condensed-regular,
.tds-text-weight-condensed-regular {
  @include tdt-primary-font("condensed-regular");
}

.tds-text-condensed-medium,
.tds-text-weight-condensed-medium {
  @include tdt-primary-font("condensed-medium");
}

/*
|===============================================
| Alignment
|===============================================
*/

.tds-text-align-left {
  text-align: left;
}

.tds-text-align-right {
  text-align: right;
}

.tds-text-align-center {
  text-align: center;
}

/*
|===============================================
| Contextual Utilities
|===============================================
*/

.tds-text-link {
  background-color: transparent;
  border: none;
  color: $c11n-color-interactive-primary;
  cursor: pointer;
  font-size: inherit;
  font-weight: 500;
  line-height: inherit;
  padding: 0;
  text-decoration: none;

  &:focus {
    @include focus-state;
  }

  &:hover, &:focus {
    color: $c11n-color-interactive-primary;
    text-decoration: underline;
  }
}

.tds-text-link-inline {
  @extend .tds-text-link;
  text-decoration: underline;
}

.tds-text-with-links {
  a {
    @extend .tds-text-link-inline;
  }
}

.tds-text-error {
  color: $error-color !important;
}

.tds-text-success {
  color: $c11n-color-success-400;
}

.tds-text-disabled:not(.tds-form-field__label) {
  color: $c11n-color-ink-300 !important;
  pointer-events: none;
}

/*
|===============================================
| Font Size
|===============================================
*/

.tds-text-xxs {
  @include tds-text-xxs();

  &.tds-text-crop {
    @include tds-text-crop(1, -1px, 1px);
  }
}

.tds-text-xs {
  @include tds-text-xs();

  &.tds-text-crop {
    @include tds-text-crop(1.3333, -1px, 2px);
  }
}

.tds-text-s {
  @include tds-text-s();

  &.tds-text-crop {
    @include tds-text-crop(1.42857, -1px, 2px);
  }
}

.tds-text-m {
  @include tds-text-m();

  &.tds-text-crop {
    @include tds-text-crop(1.5, -1px, 2px);
  }
}

.tds-text-l {
  @include tds-text-l();

  &.tds-text-crop {
    @include tds-text-crop(1.3333, -2px, 3px);
  }
}

.tds-text-xl {
  @include tds-text-xl();

  &.tds-text-crop {
    @include tds-text-crop(1.28571, -3px, 4px);
  }
}

.tds-text-xxl {
  @include tds-text-xxl();

  &.tds-text-crop {
    @include tds-text-crop(1.18182, -5px, 8px);
  }
}

.tds-text-xxxl {
  @include tds-text-xxxl();

  &.tds-text-crop {
    @include tds-text-crop(1.11765, -8px, 12px);
  }
}