.tds-block {
  display: block;
}

.tds-hidden {
  display: none;
}

@media (min-width: $breakpoints_m) {
  .tds-md-block {
    display: block;
  }
}

@media (min-width: $breakpoints_m) {
  .tds-md-none {
    display: none;
  }
}

@media (min-width: $breakpoints_s) {
  .tds-sd-block {
    display: block;
  }
}

@media (min-width: $breakpoints_s) {
  .tds-sd-none {
    display: none;
  }
}

.tds-invisible {
  opacity: 0;
}

.tds-position-absolute {
  position: absolute;
}

.tds-position-relative {
  position: absolute;
}

.tds-float-left {
  float: left;
}

.tds-float-right {
  float: right;
}

.tds-pointer-events-none {
  pointer-events: none;
}

.tds-modal-open,
.tds-loader-open {
  overflow: hidden;
}

@media (max-width: $breakpoints_m) {
  .tds-guidelet-open {
    overflow: hidden;
  }
}
