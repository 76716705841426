@import "@vg-constellation/styles/variables";

.tds-button {
  @include tdt-primary-font('medium');
  @include tds-text-m;
  background-color: $c11n-color-red-800;
  border: 2px $border-style-default transparent;
  border-radius: $border-radius-default * 10;
  color: $white;
  cursor: pointer;
  display: inline-block;
  height: 48px;
  padding: $spacing-squash-6;
  text-align: center;
  text-decoration: none;
  transition-duration: $animation-timing-expand-small;
  transition-property: background-color, box-shadow, color, border-color, border-width;
  transition-timing-function: $animation-easing-standard-web;
  z-index: $c11n-elevation-z-index-0;

  &:hover {
    background-color: $c11n-color-red-600;
    box-shadow: 0 0 0 2px $c11n-color-red-600;
    outline: 0;
  }

  &:focus {
    background-color: $c11n-color-red-600;
    outline: 0;
    box-shadow:
      0 0 0 2px $c11n-color-red-600,
      0 0 0 3px $white,
      0 0 0 5px $tds-color-focus-outside;
  }

  &:active {
    background-color: $black;
    box-shadow:
      0 0 0 2px transparent,
      0 0 0 3px $white,
      0 0 0 5px $tds-color-focus-outside;
  }

  &:disabled,
  &[disabled] {
    cursor: default;
    opacity: 0.32;
    pointer-events: none;
  }

  &--block {
    display: block;
    width: 100%;
  }

  &--secondary {
    background-color: $white;
    box-shadow: none;
    color: $c11n-color-ink-400;
    border: 2px $border-style-default $c11n-color-ink-400;
    height: 48px;

    &:hover {
      border-color: transparent;
      background-color: $white;
      box-shadow: 0 0 0 2px $c11n-color-ink-400;
    }

    &:focus {
      border-color: transparent;
      background-color: $white;
      outline: 0;
      box-shadow:
        0 0 0 2px $c11n-color-ink-400,
        0 0 0 3px $white,
        0 0 0 5px $tds-color-focus-outside;
    }

    &:active {
      box-shadow:
        0 0 0 2px transparent,
        0 0 0 3px $white,
        0 0 0 5px $tds-color-focus-outside;
      background-color: $black;
      color: $white;
    }
  }

  &--blue-text-link {
    background-color: transparent;
    border-radius: 0;
    box-shadow: 0 0 0 0 transparent;
    color: $c11n-color-interactive-primary;
    height: 36px;
    padding: 0 $spacing-4;
    text-transform: uppercase;
    transition-property: box-shadow, transform, padding;

    &:hover {
      background-color: $c11n-color-ink-200;
      box-shadow: none;
      color: $c11n-color-interactive-primary;
    }
    
    &:focus {
      @include focus-state;
      background-color: $c11n-color-ink-200;
      color: $c11n-color-interactive-primary;
      outline: 0;
    }

    &:active {
      background-color: $black;
      color: $white;
    }

    &.tds-button--compact {
      padding: 0 $spacing-4;
    }
  }

  &--compact {
    font-size: $s-font-size;
    padding: $spacing-squash-5;
    height: 36px;
  }
  &--compact-below-xl {
    @media (max-width: $breakpoints-xl) {
      @include tds-text-s;
      padding: $spacing-squash-5;
      height: 36px;
      line-height: $s-font-size;
    }
  }
}
