@import "@vg-constellation/styles/variables";

$chunky-border: 2px solid $c11n-color-ink-400;

.tds-lot-table {
  border-collapse: separate;
  border-spacing: 0 $spacing-5;
  width: 100%;

  &__header {
    @include tdt-primary-font("medium");
    @include tds-text-xxs;
    background-color: $white;
    color: $c11n-color-ink-400;
    border-bottom: $chunky-border;
    text-transform: uppercase;
    padding: $spacing-5 0;

    &--sticky {
      position: sticky;
      top: 0;
      z-index: $c11n-elevation-z-index-400;
    }
  }

  &__subheader {
    @include tdt-primary-font("bold");
    @include tds-text-xxs;
    background-color: $c11n-color-ink-400;
    border-top-left-radius: $border-radius-default;
    border-top-right-radius: $border-radius-default;
    color: $white;
    padding: 10px $spacing-5;
    text-align: left;
    text-transform: uppercase;

    &--sticky {
      position: sticky;
      top: 34px;
      z-index: $c11n-elevation-z-index-400;
    }
  }

  &__title {
    @include tdt-primary-font("bold");
    @include tds-text-xxs;
    text-transform: uppercase;
    color: $black;
  }

  &__data {
    @include tdt-primary-font("regular");
    @include tds-text-s;
    color: $black;
    border-bottom: $border-width-default $border-style-default $c11n-color-ink-300;
    padding-bottom: $spacing-5;
    position: relative;

    &--last {
      border-bottom: $chunky-border;
    }
  }

  &__total {
    @include tdt-primary-font("bold");
    @include tds-text-s;
    color: $black;
    line-height: 10px;
  }

  &__input {
    display: inline-block;
    width: 130px;
  }

  &__button {
    @extend .tds-text-link;
    align-items: center;
    display: inline-flex;
    text-transform: uppercase;
  }

  &__shape {
    position: absolute;
    transform: translate(4px, 6px);
  }
}
