/*
|=====================================================================
| Bootstrap Utilities
|=====================================================================
*/

@import '../node_modules/bootstrap/scss/functions';
@import '../node_modules/bootstrap/scss/variables';
@import '../node_modules/bootstrap/scss/mixins';
@import '../node_modules/bootstrap/scss/helpers';
@import '../node_modules/bootstrap/scss/maps';
@import '../node_modules/bootstrap/scss/utilities';
@import '../node_modules/bootstrap/scss/utilities/api';

/*
|=====================================================================
| Trade Design System
|=====================================================================
|
| Note that the Trade Design System provides access to design tokens.
| The design tokens are layers of sass variables that help us build
| components that adhere to the design system. A few mixins and
| maps are available as well. Use the TDS storybook repo first
| and reach for TDT only as a last resort.
|
| See the Trade Design Tokens repo for more details:
| https://bitbucket.vanguard.com/projects/RTLTL/repos/vg-trade-design-tokens.npm/browse
|
*/

@import 'vg-trade-design-system/scss/main';

/*
|=====================================================================
| Constellation
|=====================================================================
|
| See the Constellation repo for more details:
| https://bitbucket.opst.c1.vanguard.com/projects/CONSTELLATION/repos/constellation.monorepo/browse/README.md
| https://constellation.web.vanguard.com/angular-13/1.1.0/?path=/story/getting-started--page
|
*/

@import '@vg-constellation/angular-17/styles/c11n';
@import './assets/scss/base/c11n-overrides';

/*
|=====================================================================
| Grid System
|=====================================================================
*/

@import './assets/scss/abstracts/bootstrap-variables';
@import './assets/scss/base/bootstrap-overrides';

/*
|=====================================================================
| TWE Styles
|=====================================================================
|
| TWE-specific styles and abstracts.
|
*/

// Base
@import './assets/scss/base/reboot';
@import './assets/scss/base/typography';
@import './assets/scss/base/layout';
@import './assets/scss/base/card';

// Components
@import './assets/scss/components/lists';
@import './assets/scss/components/borders';
@import './assets/scss/components/buttons';

// tds overrides for c11n
@import './assets/scss/base/tds-overrides-for-c11n';

/*
|=====================================================================
| VGN Header / Footer Overrides
|=====================================================================
|
| VGN v6.0.0 uses the CXD DSM styling, but it is not ready.
| For now, we are using an older version and tweaking the
| container sizes.
|
*/

.guidelet-flex-container {
  align-items: center;
  display: flex;
  margin-bottom: 2px;
  margin-top: 3px;

  legend {
    margin-bottom: 0px;
    width: auto;
  }

  &--mt-0 {
    margin-top: 0;
  }
}

/*
|=====================================================================
| Styles for C11n Details controls
|=====================================================================
|
| These styles cause the "show details" controls to scroll down with the content when clicked.
| Note: the first selector is a class rather than ID because IDs cause cypress A11y tests to fail with duplicate ID errors
| (for reasons that remain unclear). Also note that these styles must live here, rather than in the component CSS files,
| otherwise C11n styles take precedence.
|
*/
.quote-details > details summary {
  margin-top: 0.5rem;
}
.quote-details > details[open] summary {
  position: absolute;
  bottom: -2.5rem;
  left: 10%;
  right: 10%;
}

.balances-details > details[open] summary {
  position: absolute;
  bottom: -2.5rem;
  right: 0;
}

.icon-container {
  color: $c11n-color-alert-400;
}

// define custom properties for grid breakpoints.
// (todo: can be removed once we upgrade to Bootstrap 5.3).
:root {
  @each $breakpoint, $value in $grid-breakpoints {
    --breakpoint-#{$breakpoint}: #{$value};
  }
}
