@import '@vg-constellation/tokens/tokens';

#tds-select-2-label {
  display: none;
}

body {
  .tds-text-regular,
  .tds-text-weight-regular,
  .tds-text-bold,
  .tds-list-group__data,
  .tds-order-table__data:first-child,
  .tds-table-theme-holdings .tds-table__data:nth-child(2),
  .tds-table-theme-holdings .tds-table__data:first-child,
  .tds-text-weight-medium,
  .tds-text-light,
  .tds-text-weight-light,
  .tds-list-group__item {
    font-family: $c11n-font-family-sans;
  }

  .tds-list-group__item {
    font-size: $c11n-size-typography-sm;

    @include media-breakpoint-up(lg) {
      font-size: $c11n-size-typography-md;
    }
  }
}

.twe-mt-n075 {
  margin-top: -0.75rem;
}
