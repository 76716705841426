@import '@vg-constellation/angular-17/styles/breakpoints';
@import '@vg-constellation/tokens/tokens';
@import '@vg-constellation/angular-17/styles/variables';

.twe-secondary-link {
  text-decoration: none;
  color: $c11n-color-interactive-secondary;

  &:hover {
    color: $c11n-color-interactive-secondary;
    text-decoration: underline;
  }

  &:focus {
    text-decoration: underline;
  }
}

.twe-valign-middle {
  vertical-align: middle;
}

.twe-text-black {
  color: $black;
}

.twe-c11n-color-ink-400 {
  color: $c11n-color-ink-400;
}

.twe-c11n-color-ink-600 {
  color: $c11n-color-ink-600;
}

.twe-c11n-color-ink-800 {
  color: $c11n-color-ink-800;
}

.twe-c11n-color-red-800 {
  color: $c11n-color-red-800;
}

.twe-text-confirmation {
  color: $success-color;
}

// responsive class for h2 elements
.twe-responsive-h2-header {
  @include c11n-font-rules('bold', 'md');
  @include c11n-text-crop-md();

  @include media-breakpoint-up(xl) {
    @include c11n-font-rules('bold', 'lg');
    @include c11n-text-crop-lg-bold();
  }
}

.twe-text-bold {
  font-weight: 700 !important;
  font-family: $c11n-font-family-sans;
}
