.row {
  @media (min-width: map-get($grid-breakpoints, xxl)) {
    margin-left: map-get($grid-gutter-widths, xxl) * (-1);
    margin-right: map-get($grid-gutter-widths, xxl) * (-1);
  }
  @media (min-width: map-get($grid-breakpoints, xl)) and (max-width: map-get($grid-breakpoints, xxl) - 1px) {
    margin-left: map-get($grid-gutter-widths, xl) * (-1);
    margin-right: map-get($grid-gutter-widths, xl) * (-1);
  }
  @media (min-width: map-get($grid-breakpoints, lg)) and (max-width: map-get($grid-breakpoints, xl) - 1px) {
    margin-left: map-get($grid-gutter-widths, lg) * (-1);
    margin-right: map-get($grid-gutter-widths, lg) * (-1);
  }
  @media (min-width: map-get($grid-breakpoints, md)) and (max-width: map-get($grid-breakpoints, lg) - 1px) {
    margin-left: map-get($grid-gutter-widths, md) * (-1);
    margin-right: map-get($grid-gutter-widths, md) * (-1);
  }
  @media (min-width: map-get($grid-breakpoints, sm)) and (max-width: map-get($grid-breakpoints, md) - 1px) {
    margin-left: map-get($grid-gutter-widths, sm) * (-1);
    margin-right: map-get($grid-gutter-widths, sm) * (-1);
  }
  margin-left: map-get($grid-gutter-widths, xs) * (-1);
  margin-right: map-get($grid-gutter-widths, xs) * (-1);
}

[class^='col'],
[class*=' col'] {
  @media (min-width: map-get($grid-breakpoints, xxl)) {
    padding-left: map-get($grid-gutter-widths, xxl);
    padding-right: map-get($grid-gutter-widths, xxl);
  }
  @media (min-width: map-get($grid-breakpoints, xl)) and (max-width: map-get($grid-breakpoints, xxl) - 1px) {
    padding-left: map-get($grid-gutter-widths, xl);
    padding-right: map-get($grid-gutter-widths, xl);
  }
  @media (min-width: map-get($grid-breakpoints, lg)) and (max-width: map-get($grid-breakpoints, xl) - 1px) {
    padding-left: map-get($grid-gutter-widths, lg);
    padding-right: map-get($grid-gutter-widths, lg);
  }
  @media (min-width: map-get($grid-breakpoints, md)) and (max-width: map-get($grid-breakpoints, lg) - 1px) {
    padding-left: map-get($grid-gutter-widths, md);
    padding-right: map-get($grid-gutter-widths, md);
  }
  @media (min-width: map-get($grid-breakpoints, sm)) and (max-width: map-get($grid-breakpoints, md) - 1px) {
    padding-left: map-get($grid-gutter-widths, sm);
    padding-right: map-get($grid-gutter-widths, sm);
  }
  padding-left: map-get($grid-gutter-widths, xs);
  padding-right: map-get($grid-gutter-widths, xs);
}

.container {
  @media (min-width: map-get($grid-breakpoints, xxl)) {
    padding-left: 0;
    padding-right: 0;
    max-width: map-get($container-max-widths, xxl) - 1px;
  }
  @media (min-width: map-get($grid-breakpoints, xl)) and (max-width: map-get($grid-breakpoints, xxl) - 1px) {
    padding-left: map-get($grid-gutter-widths, xl);
    padding-right: map-get($grid-gutter-widths, xl);
  }
  @media (min-width: map-get($grid-breakpoints, lg)) and (max-width: map-get($grid-breakpoints, xl) - 1px) {
    padding-left: map-get($grid-gutter-widths, lg);
    padding-right: map-get($grid-gutter-widths, lg);
  }
  @media (min-width: map-get($grid-breakpoints, md)) and (max-width: map-get($grid-breakpoints, lg) - 1px) {
    padding-left: map-get($grid-gutter-widths, md);
    padding-right: map-get($grid-gutter-widths, md);
  }
  @media (min-width: map-get($grid-breakpoints, sm)) and (max-width: map-get($grid-breakpoints, md) - 1px) {
    padding-left: map-get($grid-gutter-widths, sm);
    padding-right: map-get($grid-gutter-widths, sm);
  }
  padding-left: map-get($grid-gutter-widths, xs);
  padding-right: map-get($grid-gutter-widths, xs);
}

.container-fluid {
  @extend .container-lg;
  @extend .container-xl;
  @extend .container-xxl;
}
