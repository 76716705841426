/*
|===============================================
| Margin
|===============================================
*/

.tds-m {
  @include tds-margin-generator;
}

.tds-mt {
  @include tds-margin-top-generator;
}

.tds-mr {
  @include tds-margin-right-generator;
}

.tds-mb {
  @include tds-margin-bottom-generator;
}

.tds-ml {
  @include tds-margin-left-generator;
}

.tds-mx {
  @include tds-margin-x-generator;
}

.tds-my {
  @include tds-margin-y-generator;
}

/*
|===============================================
| Padding
|===============================================
*/

.tds-p {
  @include tds-padding-generator;
}

.tds-pt {
  @include tds-padding-top-generator;
}

.tds-pr {
  @include tds-padding-right-generator;
}

.tds-pb {
  @include tds-padding-bottom-generator;
}

.tds-pl {
  @include tds-padding-left-generator;
}

.tds-px {
  @include tds-padding-x-generator;
}

.tds-py {
  @include tds-padding-y-generator;
}
