/**
 * Add an elevation to a class.
 */
@mixin tdt-elevation($z-value) {
  @if ($z-value < 0 or $z-value > 24) {
    @error "$z-value must be between 0 and 24 but received '#{$z-value}'";
  }

  $umbra-height: map-get($elevation-height-umbra-map, "elevation-height-umbra-#{$z-value}");
  $penumbra-height: map-get($elevation-height-penumbra-map, "elevation-height-penumbra-#{$z-value}");
  $ambient-height: map-get($elevation-height-ambient-map, "elevation-height-ambient-#{$z-value}");

  box-shadow:
    $umbra-height $elevation-color-umbra,
    $penumbra-height $elevation-color-penumbra,
    $ambient-height $elevation-color-ambient;
}
