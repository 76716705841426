@import '@vg-constellation/angular-17/styles/variables';

.twe-flex-button-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  flex-direction: row;
  margin-right: -$c11n-size-space-2x;
  margin-bottom: -$c11n-size-space-1x;
  &__button {
    margin-right: $c11n-size-space-2x;
    margin-bottom: $c11n-size-space-1x;
    &--ml0 {
      margin-left: 0 !important;
    }
  }

  // Edge case: Intended to help awkward layout issues we're seeing when buttons wrap to two rows
  // due to new c11n breakpoints.
  @include media-breakpoint-down(sm) {
    flex-direction: column-reverse;
  }

  &__column-reverse-lg {
    @include media-breakpoint-up(lg) {
      flex-direction: column-reverse;
    }
  }
}

.c11n-link {
  cursor: pointer;
}
