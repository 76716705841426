@use 'sass:math';
@import "@vg-constellation/styles/variables";


/*
|===============================================
| Form Field
|===============================================
|
| A form field is a basic container for form
| controls like inputs, labels, and messages.
|
*/

.tds-form-field {
  @extend %form-field;
  position: relative;

  &__label {
    @extend %form-field__label;
  }

  &__message {
    @extend %form-field__message;
  }
}

/*
|===============================================
| Form Control Label
|===============================================
|
| A label that sits inside of the input and
| transitions up when the input becomes active.
|
*/

// Placeholder state
// ===

.tds-form-control__label {
  background-color: $white;
  color: $c11n-color-ink-300;
  display: inline-block;
  font-size: $xs-font-size;
  padding: $spacing-squash-1;
  pointer-events: none;
  position: absolute;
  margin: 0 0 0 12px;
  top: 0;
  transform: translateY($spacing-5);
  transition: transform $animation-timing-expand-small;
}

// Label state
// ===

.tds-form-control input:not(:disabled):focus ~ .tds-form-control__label,
.tds-form-control input:not(.tds-empty-input) ~ .tds-form-control__label,
.tds-form-control__label--raised {
  color: $c11n-color-ink-600;
  transform: translateY(-6px);
}

// Disabled state
// ===

.tds-form-control input:disabled ~ .tds-form-control__label {
  color: $c11n-color-ink-300;
}

/*
|===============================================
| Form Control Icon
|===============================================
*/

.tds-form-control__icon {
  left: $spacing-6;
  position: absolute;

  &--error {
    fill: $error-color;
  }

  &--right {
    left: auto;
    right: $spacing-6;
  }

  &--clear {
    @extend .tds-form-control__icon--right;
    border: none;
    cursor: pointer;
    height: 32px;
    border-radius: 100%;
    width: 32px;
    padding: 0;
    top: calc(50% - 16px);
    background-color: transparent;

    &:hover,
    &:focus {
      fill: $black;
      background-color: $c11n-color-ink-100;
    }

    &:active {
      background-color: $c11n-color-ink-600;
      fill: $white;
    }
  }

  &--loading {
    @extend .tds-form-control__icon--right;
    height: 22px;
    top: calc(50% - 11px);
  }
}

/*
|=====================================================================
| Form Control Lead Character
|=====================================================================
*/

.tds-form-control__lead-char {
  @include tds-text-m;
  color: $c11n-color-ink-400;
  left: $spacing-6;
  position: absolute;
  pointer-events: none;
  top: calc(50% - #{math.div($m-font-line-height, 2)});
}

/*
|===============================================
| Form Control Mini-Toggle
|===============================================
*/

.tds-form-control__toggle {
  position: absolute;
  right: 3px;
  top: 25px;
}
