@import "@vg-constellation/styles/variables";

.tds-inline-list {
  list-style: none;
  margin: 0;
  margin-bottom: -8px;
  padding: 0;

  &__item {
    display: inline-block;
    margin-bottom: 8px;

    & + .tds-inline-list__item {
      margin-left: $spacing-2;
      padding-left: $spacing-2;
    }
  }
}

.tds-inline-list-theme-bordered {
  & .tds-inline-list__item + .tds-inline-list__item {
    border-left: $border-width-default $border-style-default $c11n-color-ink-300;
  }
}

.tds-inline-list-theme-medium {
  & .tds-inline-list__item + .tds-inline-list__item {
    margin-left: $spacing-4;
    padding-left: $spacing-4;
  }
}

.tds-inline-list-theme-large {
  & .tds-inline-list__item + .tds-inline-list__item {
    margin-left: $spacing-6;
    padding-left: $spacing-6;
  }
}