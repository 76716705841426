@import "@vg-constellation/styles/variables";

.tds-square {
  background-color: $c11n-color-cobalt-500;
  display: inline-block;
  height: 8px;
  width: 8px;
}

.tds-circle {
  background-color: $c11n-color-yellow-600;
  border-radius: 100%;
  display: inline-block;
  height: 8px;
  width: 8px;
}
