/**
 * Add the font family and weight to a class. Please note that the
 * placeholder hack is a workaround for dynanmic variable names.
 */

%font-family-light {
  font-family: $font-family-light;
  font-weight: $font-weight-light;
}
%font-family-regular {
  font-family: $font-family-regular;
  font-weight: $font-weight-regular;
}
%font-family-regular-italic {
  font-family: $font-family-regular-italic;
  font-weight: $font-weight-regular;
}
%font-family-medium {
  font-family: $font-family-medium;
  font-weight: $font-weight-medium;
}
%font-family-bold {
  font-family: $font-family-bold;
  font-weight: $font-weight-bold;
}
%font-family-condensed-regular {
  font-family: $font-family-condensed-regular;
  font-weight: $font-weight-regular;
}
%font-family-condensed-light {
  font-family: $font-family-condensed-light;
  font-weight: $font-weight-light;
}
%font-family-condensed-medium {
  font-family: $font-family-condensed-medium;
  font-weight: $font-weight-medium;
}

@mixin tdt-primary-font($style: 'regular') {
  @extend %font-family-#{$style};
}
